.PhoneInput{
  display: flex;

  .PhoneInputCountry {
    margin-right: 20px;
    width : 40%;
    display: flex;
  }

  .PhoneInputCountryIcon{
    display: none;
  }

  
}

.ant-select-selector {
  width: 100%;
  height: 45px !important;
  padding: 7px 11px !important;;
}