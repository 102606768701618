.user-container {
  a {
    color: #242424;
    float: right;
    font-size: 15px;
    @media #{$xs-layout} {
      font-size: 14px;
    }
    transition: all 0.3s ease 0s;
    &:hover {
      color: $theme-color;
    }
  }

  button {
    background-color: #f2f2f2;
    border: medium none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 11px 30px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    &:hover {
      background-color: $theme-color;
      color: #fff;
    }
  }
}

