.pro-details-color-content {
  background: #dbdbdb;
  padding: 5px 10px;
  margin: 5px 5px;
  border-radius: 2px;
  color: #676666;

  span {
    padding-right: 5px;
    text-transform: capitalize;
  }
}

.shopping-cart-content,
.account-dropdown {
  top: 160%;

  @media #{$xs-layout} {
    top: 100%;

    margin-top: 14px;
  }

  @media #{$md-layout} {
    top: 100%;

    margin-top: 14px;
  }
}

.shopping-cart-content {
  position: absolute;
  z-index: 9999;
  top: 190%;
  right: 0;

  width: 340px;
  padding: 10px 35px 10px;

  transition: all 0.5s ease 0s;
  /* transform: rotateX(90deg); */
  transform-origin: center top 0;

  border: 1px solid #ebebeb;
  border-radius: 5px;
  background: #fff none repeat scroll 0 0;

  &.cart-visible,
  &.active {
    visibility: visible;

    transform: rotateX(0deg);

    opacity: 1;
  }

  @media #{$xs-layout} {
    top: 100%;
    right: -52px;

    overflow-y: auto;

    width: 295px;
    height: 300px;
    margin-top: 14px;
    padding: 31px 15px 10px;
  }

  @media #{$md-layout} {
    top: 100%;

    overflow-y: auto;

    height: 300px;
    margin-top: 18px;
    margin-top: 14px;
  }

  @media #{$lg-layout} {
    overflow-y: auto;
  }

  ul {
    overflow-y: auto;

    max-height: 400px;
    margin-right: -15px;
    padding-top: 20px;
    padding-right: 15px;

    li {
      position: relative;

      display: flex;
      flex-wrap: nowrap;

      margin-bottom: 20px;
      padding-bottom: 15px;

      border-bottom: 1px solid #ebebeb;

      .shopping-cart-img {
        flex: 0 0 82px;

        margin-right: 15px;
      }

      .shopping-cart-title {
        h4 {
          font-size: 15px;
          line-height: 16px;

          margin: 0;
          margin-right: 20px;

          a {
            line-height: 1.2;

            color: #000;

            &:hover {
              color: $theme-color;
            }
          }
        }

        h6 {
          font-size: 13px;

          margin: 5px 0 8px;
        }

        span {
          font-size: 14px;
        }
      }

      .shopping-cart-delete {
        position: absolute;
        top: 0;
        right: 0;

        a,
        button {
          position: relative;

          display: inline-block;

          padding: 0;

          border: none;
          background: transparent;
          font-size: 16px;

          color: $theme-color;

          &:hover {
            color: #333;
          }
        }
      }
    }
  }

  .shopping-cart-total {
    h4 {
      font-size: 14px;

      margin-top: 17px;
      margin-bottom: 17px;

      span {
        float: right;
      }
    }
  }

  .shopping-cart-btn {
    margin-top: 23px;

    a {
      font-size: 14px;
      font-weight: 500;

      display: block;

      margin-bottom: 20px;
      padding: 16px 40px 17px;
    }
  }
}

.same-style.header-search {
  .search-content {
    position: absolute;
    z-index: 99;
    top: 193%;
    right: 0;

    min-width: 300px;
    padding: 15px;

    transition: all 0.5s ease 0s;
    transform-origin: center top 0;

    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);

    &.active {
      visibility: visible;

      transform: rotateX(0deg);

      opacity: 1;
    }

    @media #{$md-layout} {
      top: 154%;
    }

    @media #{$xs-layout} {
      top: 154%;
      right: -105px;

      min-width: 260px;
    }

    form {
      position: relative;

      input {
        width: 100%;
        padding: 5px 72px 5px 15px;

        transition: all 0.4s ease 0s;

        color: #000;
        border: 1px solid #d3d3d3;
        outline: medium none;
        background: transparent;
      }

      .button-search {
        font-size: 20px;
        line-height: 1;

        position: absolute;
        top: 0;
        right: 0;

        height: 100%;
        padding: 5px 20px 2px;

        transition: all 0.4s ease 0s;
        text-transform: uppercase;

        color: #ffffff;
        border: none;
        border-color: #ce9634;
        background: $theme-color;

        &:hover {
          background-color: #000;
        }
      }
    }
  }
}


.testimonial-icon {
  font-size: 24px;
  color: $theme-color;
}

.logo-container {
  margin-top: 5px;
}

.logo-img {
  @media #{$md-layout} {
    width: 120%;
    margin-top: -25px;
  }

  @media #{$xs-layout} {
    width: 110%;
    margin-top: -2px;
  }
}

.home-parallax {
  background-image: url("/assets/images/parallax.jpg");
  background-size: cover;

  @media #{$xs-layout} {
    background-image: url("/assets/images/parallax-m.jpg");
    background-size: cover;
  }
}

.old {
  margin-right: 10px;
  text-decoration: line-through;
  color: #8e8e8e;
}

.fb-icon {
  width: 5%;
}

.google-icon {
  width: 5%;
}

.insta-icon {
  width: 5%;
}

.loading-order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    max-width: 100%;
  }

  h3 {
    color: #555252;
  }
}

.np-not-found {
  width: 100%;
  display: flex;
  justify-content: center;
}

.np-loading {
  width: 100%;
  display: flex;
  justify-content: center;

  .fa-spin {
    color: $theme-color;
    font-size: 50px;
  }
}


.slick-next:before,
.slick-prev:before {
  font-size: 35px;
  line-height: 1;
  opacity: .75;
  color: #2c2c2c;
}

.slick-prev {
  left: 50px;
  z-index: 100;
}

.slick-next {
  right: 50px;
}

.footer-area {
  margin-top: 35px;
}

button {
  &.active {
    .fa {
      color: $theme-color;
      ;
    }
  }
}

.align-btns {
  display: flex;

  button {
    margin: 5px;
  }
}

.video-banner {
  background-image: url(/assets/images/home-banner/b1.jpg);
  background-attachment: fixed;
  background-size: cover;
  position: relative;

  &::after {
    background: linear-gradient(45deg, #28b1ff 0%, #ff7bdb 21%, #db7dc2 53%, #1fb2ff 84%, #ff90e1 100%);
    opacity: 0.8;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.header-right-wrap {

  .account-setting {}

  .header-compare {
    @media #{$xs-layout} {
      display: none;
    }
  }

  .header-wishlist {
    @media #{$xs-layout} {
      display: none;
    }
  }
}

.mobile-menu-header {
  display: inline-flex;

  .header-compare,
  .header-wishlist {
    font-size: 24px;
    position: relative;
    display: inline-block;
    padding: 0;
    color: #000;
    border: none;
    background: transparent;
    margin-right: 25px;

    .count-style {
      font-size: 12px;
      line-height: 21px;
      position: absolute;
      top: -9px;
      right: -14px;
      display: inline-block;
      width: 21px;
      height: 21px;
      text-align: center;
      color: #fff;
      border-radius: 100%;
      background-color: #000;
    }
  }
}

.header-block {
  display: flex !important;
  justify-content: center;
  align-self: center;

  @media #{$xs-layout} {
    display: none !important;
  }

  .main-menu {
    ul {
      margin-top: 10px;

      li {
        a {
          line-height: 30px;
        }
      }
    }
  }
}

.stick {
  .header-block {
    .main-menu {
      ul {
        li {
          a {
            line-height: 30px;
          }
        }
      }
    }
  }
}

.errormsg {
  color: red;
  padding-top: 5px;
}

.home-title {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  transition: all 0.3s ease 0s;
  color: #555;
  /* display: flex; */
  text-align: center;
  padding-bottom: 30px;
  font-family: 'Josefin Sans';
  padding-top: 45px;
}

.np-image-grid {
  display: flex;
  flex-flow: wrap;
  justify-content: center;

  .np-image-grid-single {
    max-width: 45%;
    margin: 5px;
  }
}

.np-top-products {
  display: flex;
  overflow-x: auto;

  .np-top-products-single {
    min-width: 50%;
  }
}

.support-block {
  margin: 10px;
  width: 40%;

  .support-icon-7 {
    min-height: 50px;
  }

  .support-content-7 {
    h5 {
      font-size: 16px;
    }
  }
}

.video-popup__content {

  .preview-container {
    @media #{$xs-layout} {
      display: flex;
      justify-content: center;
    }

    .preview-btn {
      background-color: #000;
      padding: 35px 85px;

      i {
        color: $theme-color
      }
    }
  }
}

.single-image {
  h5 {
    text-align: center;
    padding: 10px;
    font-size: 18px;
    font-family: 'Josefin Sans';
  }
}

.mobile-cart-menu {
  position: absolute;
  margin-top: 25px;
  margin-right: 40px;
}


.order-status-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  padding: 80px;
  text-align: left;

  input {
    background-color: transparent;
    border: 1px solid #ebebeb;
    color: #333;
    font-size: 14px;
    height: 45px;
    margin-bottom: 30px;
    padding: 0 15px;
  }

  button {
    background-color: #f2f2f2;
    border: medium none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 11px 30px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;

    &:hover {
      background-color: #df226f;
      color: #fff;
    }
  }
}

.preview-container1 {
  @media #{$xs-layout} {
    display: flex;
    justify-content: center;
  }

  .preview-btn {
    background-color: #000;
    padding: 25% 50%;
    margin-bottom: 5%;

    i {
      color: $theme-color
    }
  }

  .preview-btn12 {
    border: none;
    padding: 0;
    margin:3%;
  }

    .video-popup__image {
      position: relative;
      height: 20rem;
      width: 17rem;
      border: 6px solid #cacaca;
      border-radius: 3px;

      img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
      }

      &:hover {
        border: 6px solid #eb0a95;

        i {
          font-size: 4rem;
        }
      }
    }
    
    .video-popup__image i {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      color: $theme-color;
      font-size: 3rem;
    }
  
  
}